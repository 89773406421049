<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('department', 1) })"
    :value="value"
    :load="loading"
    :max-width="max ? '1800px' : '800px'"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row class="py-3">
      <v-col cols="12">
        <ValidationProvider
          vid="name"
          :name="$tc('name', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            autofocus
            name="name"
            :error-messages="errors[0]"
            v-model="department.name"
            outlined
            color="secondary"
            :label="$tc('name', 1)"
          />
        </ValidationProvider>
      </v-col>
      <v-col v-if="!product" cols="12">
        <i-table
          app="base_config.productiondepartment"
          :title="$t('production')"
          :headers="headers"
          :items="item"
          @click:create="memberfield = true"
          dontRemove
          dontEdit
          dontSearch
          :dontCreate="!getPermissions([`users.add_profile`]) && !isAdmin"
        >
          <template v-if="department.team" v-slot:item.users="{ value }">
            <span v-if="value.length === 0">{{ $t('no_producers') }}</span>
            <span v-for="(user, i) in value" :key="`user-${i}`">
              <v-chip
                class="mr-1 mb-1"
                small
                :color="department.team.includes(user.pk) ? 'error' : 'primary'"
                @click="addMember(user.pk)"
              >
                {{ user.first_name }} {{ user.last_name }}
              </v-chip>
            </span>
          </template>
        </i-table>
      </v-col>
      <v-col cols="12">
        <dialproducer
          v-if="memberfield"
          :roleParam="true"
          @done="addProducer"
        />
      </v-col>
      <v-col cols="12">
        <ValidationProvider
          vid="description"
          :name="$tc('description', 1)"
          rules=""
          v-slot="{ errors }"
        >
          <v-skeleton-loader v-if="loading" type="article" tile />
          <label v-if="!loading" for="description">
            {{ $t('description') }}
          </label>
          <vue-editor
            v-if="!loading"
            id="description"
            v-model="department.description"
            :editorToolbar="customToolbar"
          />
          <span class="areaErrors" v-if="errors[0]">{{ errors[0] }}</span>
        </ValidationProvider>
      </v-col>
    </v-row>
  </i-modal>
</template>

<script>
import dialproducer from '../../pages/users/_pk.vue'
import { VueEditor } from 'vue2-editor'
import { mapGetters } from 'vuex'

export default {
  components: {
    VueEditor,
    dialproducer
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    product: {
      type: Boolean,
      default: false
    },
    max: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      memberfield: false,
      item: [],
      loading: false,
      department: {
        company: '',
        description: '',
        name: '',
        team: []
      },
      edit: false,
      users: [],
      customToolbar: [
        [{ header: [] }],
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'code',
          'code-block'
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }]
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = !!this.toEdit
          this.department = this.edit
            ? { ...this.toEdit }
            : { company: this.company.pk, description: '', name: '' }
        }
      }
    }
  },
  methods: {
    async getTeam() {
      try {
        const { data } = await this.$api.department.producers({
          opt: { params: { company: this.company.pk } }
        })
        this.users = data.results
        this.item = [{ rol: 'production', users: this.users }]
      } catch (error) {
        console.error('Error fetching team:', error)
        this.$toast.error(this.$t('no_producers'))
      }
    },
    async submit() {
      if (!this.loading) {
        this.loading = true
        try {
          const apiCall = this.edit
            ? this.$api.department.edit({
                pk: this.department.pk,
                form: this.department
              })
            : this.$api.department.create({ form: this.department })
          const department = await apiCall
          this.$emit('done', department.data)
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('department', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      }
    },
    addProducer() {
      this.getTeam()
      this.memberfield = false
    },
    addMember(user) {
      const index = this.department.team.indexOf(user)
      if (index !== -1) {
        this.department.team.splice(index, 1)
      } else {
        this.department.team.push(user)
      }
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData',
      getPermissions: 'session/getPermissions'
    }),
    headers() {
      return [
        { text: this.$tc('roles', 1), value: 'rol' },
        { text: this.$tc('user', 1), value: 'users' }
      ]
    }
  },
  mounted() {
    this.getTeam()
  }
}
</script>
